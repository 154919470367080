export default {
  editorConfig: {
    startupFocus: false,
    toolbarGroups: [
      { name: "basicstyles", groups: ["basicstyles"] },
      { name: "paragraph", groups: ["list"] },
      { name: "links", groups: ["Link"] },
      { name: "styles", groups: ["styles"] },
      { name: "document", groups: ["mode"] },
    ],
    extraPlugins: "justify",
  },
};
