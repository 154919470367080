import ckeditorConfig from "@/libs/ckeditor-config";

export default [
  {
    key: "okrReviewEn",
    label: "field.okrReviewEn",
    type: "ck-editor",
    config: { ...ckeditorConfig.editorConfig },
    cols: 6,
  },
  {
    key: "okrReviewKm",
    label: "field.okrReviewKm",
    type: "ck-editor",
    config: { ...ckeditorConfig.editorConfig },
    cols: 6,
  },
  {
    key: "midYearReviewEn",
    label: "field.midYearReviewEn",
    type: "ck-editor",
    config: { ...ckeditorConfig.editorConfig },
    cols: 6,
  },
  {
    key: "midYearReviewKm",
    label: "field.midYearReviewKm",
    type: "ck-editor",
    config: { ...ckeditorConfig.editorConfig },
    cols: 6,
  },
  {
    key: "yearEndReviewEn",
    label: "field.yearEndReviewEn",
    type: "ck-editor",
    config: { ...ckeditorConfig.editorConfig },
    cols: 6,
  },
  {
    key: "yearEndReviewKm",
    label: "field.yearEndReviewKm",
    type: "ck-editor",
    config: { ...ckeditorConfig.editorConfig },
    cols: 6,
  },
  {
    key: "companyObjectiveEn",
    label: "field.companyObjectiveEn",
    type: "ck-editor",
    config: { ...ckeditorConfig.editorConfig },
    cols: 6,
  },
  {
    key: "companyObjectiveKm",
    label: "field.companyObjectiveKm",
    type: "ck-editor",
    config: { ...ckeditorConfig.editorConfig },
    cols: 6,
  },
];
