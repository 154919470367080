<template>
  <div>
    <b-card>
      <b-overlay :show="loading" variant="light">
        <validation-observer ref="editForm" #default="{ invalid }">
          <n-form-confirmation
            key="editForm"
            @submit="submit"
            :form="$refs.editForm"
            :disabled="invalid"
          >
            <n-input
              :fields="fields"
              v-model="data"
              :initValue="initData"
            ></n-input>
            <b-row>
              <b-col cols="12" class="text-right">
                <n-button-save-option
                  ref="btnSubmit"
                  @submit="submit"
                  @save="save"
                  :loading="loading"
                  :resource="resource"
                  :route="route"
                  :hide-create="true"
                  v-if="$can('update', resource)"
                ></n-button-save-option>
              </b-col>
            </b-row>
          </n-form-confirmation>
        </validation-observer>
      </b-overlay>
    </b-card>
  </div>
</template>

<script>
import { BRow, BCol, BCard, BOverlay } from "bootstrap-vue";
import Repository from "@/repositories/RepositoryFactory";
import Ripple from "vue-ripple-directive";
import NButtonLoading from "@/components/NButtonLoading";
import NFormConfirmation from "@/components/NFormConfirmation";
import NInput from "@/components/NInput";
import FormInput from "./formInput";
import NButtonSaveOption from "@/components/NButtonSaveOption";

const StepInformationRepository = Repository.get("stepInformation");

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BOverlay,

    NButtonLoading,
    NFormConfirmation,
    NInput,
    NButtonSaveOption,
  },
  directives: {
    Ripple,
  },
  computed: {},
  data() {
    return {
      id: null,
      type: 2,
      data: {
        okrReviewEn: null,
        okrReviewKm: null,
        midYearReviewEn: null,
        midYearReviewKm: null,
        yearEndReviewEn: null,
        yearEndReviewKm: null,
        companyObjectiveEn: null,
        companyObjectiveKm: null,
      },
      initData: {},
      loading: false,
    };
  },
  mounted() {
    this.show();
  },
  methods: {
    show() {
      this.loading = true;
      StepInformationRepository.show()
        .then((response) => {
          const data = response?.data?.data;
          if (data) {
            this.initData = {
              ...data,
            };
            this.id = data.id;
          }
        })
        .then(() => {
          this.loading = false;
        });
    },
    submit(type) {
      this.$refs.editForm.validate().then((success) => {
        if (success) {
          type = this.type;
          this.$refs.btnSubmit.confirm(type);
        }
      });
    },
    save(type) {
      this.loading = true;

      if (this.id != null) {
        StepInformationRepository.update(this.id, this.data)
          .then((response) => {
            this.$refs.btnSubmit.afterSave(type, response.data.data.id);
          })
          .catch((error) => {
            if (error.response?.status == 422) {
              this.$refs.editForm.setErrors(error.response?.data?.message);
            }
          })
          .then(() => {
            this.loading = false;
          });
      } else {
        StepInformationRepository.create(this.data)
          .then((response) => {
            this.id = response.data?.data?.id;
            this.$refs.btnSubmit.afterSave(type, response.data.data.id);
          })
          .catch((error) => {
            if (error.response?.status == 422) {
              this.$refs.editForm.setErrors(error.response?.data?.message);
            }
          })
          .then(() => {
            this.loading = false;
          });
      }
    },
    back() {
      this.$router.push({
        name: `list-${this.route}`,
      });
    },
  },
  setup() {
    const fields = FormInput;
    const resource = "step-information";
    const route = "step-information";

    return {
      fields,
      resource,
      route,
    };
  },
};
</script>
